import React, { useContext } from "react";
import { WidgetContext } from "components/channels/widgets/WidgetContainer";
import { map } from "lodash";
import Loading from "components/shared/Loading";
import { Article } from "components/articles/types";
import formatDate from "helpers/formatDate";
import ItemCarousel from "components/shared/ItemCarousel";
import useContainerWidth from "components/shared/hooks/useContainerWidth";

export default function BlogWidget({
  latest_articles,
}: {
  latest_articles: Article[];
}) {
  const { w, containerRef } = useContext(WidgetContext);
  const containerWidth = useContainerWidth(containerRef);

  return latest_articles ? (
    <ItemCarousel
      containerWidth={containerWidth}
      visibleItemsCount={w}
      totalItems={latest_articles?.length || 12}
      containerHeight="h-[21rem]"
    >
      {map(latest_articles, (article, index) => (
        <React.Fragment key={index}>
          <a
            className="blog_widget__title block bg-cover bg-center w-full h-[11.5rem]"
            href={`/blogs/${article.blog_id}/articles/${article.id}`}
            style={{
              backgroundImage: `url("${
                article.image
                  ? `${article.image.image_url}/660x415`
                  : Assets["blogs/article_placeholder.webp"]
              }")`,
            }}
          />
          <div className="grow">
            <a
              className="text-lg font-bold p-3 line-clamp-3 text-ellipsis overflow-hidden pb-0 text-normal"
              href={`/blogs/${article.blog_id}/articles/${article.id}`}
            >
              {article.title}
            </a>
          </div>
          {article.created_at && (
            <div className="text-sm text-muted p-3">
              {formatDate(new Date(article.created_at), "dd.MM.y")}
            </div>
          )}
        </React.Fragment>
      ))}
    </ItemCarousel>
  ) : (
    <Loading />
  );
}
