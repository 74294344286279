import React, { useEffect, useRef, useState } from "react";
import { navigate, Redirect, useParams } from "helpers/tixxt-router";
import ChannelPage from "components/channels/ChannelPage";
import { useWidget } from "components/channels/api";
import "./widgets.css";
import PageLoading from "components/shared/PageLoading";
import { createPortal } from "react-dom";
import classNames from "classnames";
import Loading from "components/shared/Loading";

export default function ShowWidgetPage() {
  const { channelSlug, widgetId } = useParams();
  const { data, isLoading } = useWidget({ channelSlug, widgetId });

  if (isLoading) {
    return <PageLoading />;
  }

  switch (data?.type) {
    case "images": {
      const images = data.images;
      return (
        <>
          <ChannelPage />
          {images && <ImagesGalleryPage {...data} images={images} />}
        </>
      );
    }
    default:
      // redirect to channel page for all widgets other than images, which should show gallery in overlay
      return window.Settings?.development ? (
        <div className={"alert alert-info prose"}>
          TODO Implement case for <code>{data?.type}</code> in ShowWidgetPage /
          WidgetPageByType
        </div>
      ) : (
        <Redirect to={`/channels/${channelSlug}`} />
      );
  }
}

type ImagesGalleryPageProps = {
  images: { id: string }[];
};

function ImagesGalleryPage({ images }: ImagesGalleryPageProps) {
  const { channelSlug } = useParams();
  const [lightbox, setLightbox] = useState<{
    id: string | null;
    open: boolean;
    loading: boolean;
  }>({ id: null, open: false, loading: false });
  const dialogRef = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    dialogRef.current?.showModal();
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return createPortal(
    <dialog
      ref={dialogRef}
      className={"tixxt__dialog rounded w-full overflow-y-scroll"}
      onClick={(e) => {
        if (e.target == e.currentTarget)
          navigate(`/channels/${channelSlug}`, { replace: true });
      }}
    >
      <div
        className={
          "grid gap-1 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6"
        }
      >
        {images.map(({ id }) => (
          <div key={id} className={"aspect-square overflow-hidden"}>
            <a
              href={`#img-${id}`}
              onClick={(e) => {
                e.preventDefault();
                setLightbox({ id, open: true, loading: lightbox.id != id });
              }}
            >
              <img
                alt=""
                aria-hidden
                src={`/api/storage/images/${id}/get/435x435`}
                className={
                  "w-full h-full object-cover object-center transition hover:scale-110"
                }
              />
            </a>
          </div>
        ))}
        {lightbox ? (
          <div
            className={classNames("images-gallery__lightbox", {
              "opacity-0 pointer-events-none": !lightbox.open,
            })}
            onClick={(e) => {
              if (e.target == e.currentTarget)
                setLightbox({ ...lightbox, open: false });
            }}
          >
            {lightbox.id && (
              <img
                key={lightbox.id}
                alt=""
                aria-hidden
                src={`/api/storage/images/${lightbox.id}/get/1920x1200`}
                onLoad={() => setLightbox({ ...lightbox, loading: false })}
              />
            )}
            <div
              className={lightbox.loading ? "bg-white p-32 rounded" : "hidden"}
            >
              <Loading key={"loading"} />
            </div>
          </div>
        ) : null}
      </div>
    </dialog>,
    document.body,
  );
}
