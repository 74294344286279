import { useMutation, UseMutationOptions, useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import queryClient from "helpers/queryClient";
import { SimpleMembership } from "../../@types";
import { ColorObject } from "components/administration/designManager/types";
import { stringify } from "query-string";
import { Article } from "components/articles/types";

type ChannelPayload = {
  id: string;
  slug: string;
  title: string;
  description: string;
  image: {
    id: string;
    name: string;
    content_type: string;
    image_url: string;
    small_thumb: string;
    large_thumb: string;
    size: number;
    extension: string;
    type: string;
  } | null;
  status: string;
  i: {
    can: { [rule: string]: boolean | undefined };
    subscribed: boolean;
    favorited: boolean;
  };
  blog_id: string;
  design: ChannelDesign | null;
};

export type ChannelDesign = {
  colors: {
    [name: string]: ColorObject | undefined;
  };
  derivedColors?: {
    [name: string]: ColorObject | undefined;
  } | null;
};

export function useChannels() {
  return useQuery<{
    start_page: ChannelPayload[];
    subscribed: ChannelPayload[];
    recent: ChannelPayload[];
    popular: ChannelPayload[];
  }>("/channels", {
    refetchOnWindowFocus: false,
    initialData: { start_page: [], subscribed: [], recent: [], popular: [] },
  });
}

export function useChannel(slug: string | undefined) {
  return useQuery<ChannelPayload>(`/channels/${slug}`, {
    enabled: !!slug,
    refetchOnWindowFocus: false,
  });
}

export function updateChannelFavorited(slug, favorited) {
  const channel = queryClient.getQueryData<ChannelPayload>(`/channels/${slug}`);
  if (!channel) return;

  queryClient.setQueryData(`/channels/${slug}`, {
    ...channel,
    i: { ...channel.i, favorited },
  });
}

type WidgetPayload = {
  type: string;
  name: string;
  title: string;
  title_background_override: null | string;
  label: null | "current" | "spotlight" | "important";
  hide_title?: boolean;
};

export type LoadedWidgetPayload = WidgetPayload & {
  id: string;
  author: SimpleMembership;
  created_at: string;
  images?: { id: string }[];
};

export const useWidgets = ({ channelSlug }: { channelSlug?: string }) =>
  useQuery<LoadedWidgetPayload[]>(`/channels/${channelSlug}/widgets`, {
    refetchOnWindowFocus: false,
    enabled: !!channelSlug,
  });

export const useWidget = ({
  channelSlug,
  widgetId,
}: {
  channelSlug?: string;
  widgetId?: string;
}) =>
  useQuery<LoadedWidgetPayload>(
    `/channels/${channelSlug}/widgets/${widgetId}`,
    {
      refetchOnWindowFocus: false,
      enabled: !!widgetId && !!channelSlug,
    },
  );

export function persistWidget(
  channelSlug: string,
  widgetId: string | undefined,
  body: WidgetPayload,
) {
  return fetchApi(
    `/channels/${channelSlug}/widgets${widgetId ? `/${widgetId}` : ""}`,
    { method: widgetId ? "PUT" : "POST", body },
  );
}
export const usePersistWidget = (
  {
    channelSlug,
    widgetId,
  }: {
    channelSlug: string;
    widgetId?: string;
  },
  options?: Omit<
    UseMutationOptions<any, { message: string }, WidgetPayload, unknown>,
    "mutationFn"
  >,
) =>
  useMutation(
    (body: WidgetPayload) => persistWidget(channelSlug, widgetId, body),
    options,
  );

type ArticlesPayload = {
  total: number;
  articles: Article[];
};

export function useArticles(channelSlug?: string, currentPage?: number) {
  return useQuery<ArticlesPayload>(
    `/channels/${channelSlug}/blog/articles?${stringify({
      page: currentPage,
    })}`,
    {
      enabled: !!channelSlug,
    },
  );
}
